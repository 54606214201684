import { Config } from "./Config";

export type VictoryInfo = {
    numWinners: number
    firstWinner?: number
    forces: number[]
    lands: number[]
    winners?: number[]
}

export class Board {
    private _victoryInfo: VictoryInfo

    constructor(private HEAP32: Int32Array, private offset: number, private config: Config) {
        this._victoryInfo = { numWinners: 0, forces: [], lands: [] }
    }

    updateVictoryInfo(victoryInfoPtr: number) {
        const forcesPtr = this.HEAP32[(victoryInfoPtr >>2)]
        const landsPtr = this.HEAP32[(victoryInfoPtr >>2)+1]
        this._victoryInfo.numWinners = this.HEAP32[(victoryInfoPtr >>2)+2]
        this._victoryInfo.firstWinner = this.HEAP32[(victoryInfoPtr >>2)+3]
        const winnersPtr = this.HEAP32[(victoryInfoPtr >>2)+4]

        this._victoryInfo.forces = []
        this._victoryInfo.lands = []
        this._victoryInfo.winners = []
        for (let s = 0; s < this.config.sides; s++) {
            this._victoryInfo.forces.push(this.HEAP32[(forcesPtr>>2)+s])
            this._victoryInfo.lands.push(this.HEAP32[(landsPtr>>2)+s])
            if (this.HEAP32[(winnersPtr>>2)+s]) {
                this._victoryInfo.winners?.push(s)
            }
        }

        return this._victoryInfo
    }

    get victoryInfo() { return this._victoryInfo }

    get cell_count() {
        return this.HEAP32[(this.offset >> 2)+1]
    }
}
