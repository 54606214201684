const PLAYER_COUNT_OFFSET = 16074

export class Config {
    constructor(private HEAP16: Int16Array, private offset: number) {}

    get sides() {
        return this.HEAP16[((this.offset + PLAYER_COUNT_OFFSET)>>1)-1]
    }

    get players() {
        return this.HEAP16[((this.offset + PLAYER_COUNT_OFFSET)>>1)]
    }
}